import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyCQWxCtJkOM7s_fhIjdlEQUnkt78bgUHQo",
    authDomain: "hiren-pipariya.firebaseapp.com",
    databaseURL: "https://hiren-pipariya.firebaseio.com",
    projectId: "hiren-pipariya",
    storageBucket: "hiren-pipariya.appspot.com",
    messagingSenderId: "869770189720",
    appId: "1:869770189720:web:c9a8b0bf44d826b2c7a9ed",
    measurementId: "G-ZNRSNDZFZR"
};
  
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

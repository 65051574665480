import React from 'react';
// import logoWhite from './Images/logo-white.png';
import './css/app.css';
import Main from './Main.js';
import Footer from './Footer.js';
function App() {
  return (
    <>
      <Main/>
      <Footer/>
   </>
  );
}

export default App;

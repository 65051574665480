import React from 'react';

class Footer extends React.PureComponent {
    render() {
        let date =  new Date().getFullYear();
        console.log(date);
    return (
        <footer className="hp-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <p>Copyright ©
                        {date} Develope by <a href="https://www.linkedin.com/in/hiren-pipariya/" rel="noopener noreferrer" target="_blank">Hiren Pipariya</a> and <br/> Design by <a href="https://ishitabhuva.now.sh" rel="noopener noreferrer" target="_blank">Ishita Bhuva</a></p>
                    </div>

                </div>
            </div>
        </footer>
    );
  }
}

export default Footer;
